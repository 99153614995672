<template>
  <div>
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <template v-else>
      <v-col cols="10" sm="8" class="pa-0 mt-10 pt-10 mx-auto">
        <div class="font-bold text-2xl sm:text-4xl mt-10 pt-10">
          {{ item.name }}
        </div>
        <!-- <div class="text-sm py-4">{{ item.lastUpdate }} | Windows</div> -->
        <div class="text-sm py-4">Windows</div>
        <v-col cols="12">
          <img
            :src="baseUrlImage + item.image.file_path"
            @error="
              $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
            "
            width="100%"
            class="rounded-md mt-8 m-auto img-detail" />
        </v-col>
        <div class="white grid justify-items-center px-0 pt-5">
          <v-col cols="12" class="py-0">
            <v-simple-table class="mb-10 mt-5">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(i, index) in headers"
                      :key="index"
                      class="header-table text-center">
                      {{ i || "-" }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="item.periods.length > 0">
                  <tr v-for="(item, index) in item.periods" :key="index">
                    <td>{{ item.startYear || "-" }}</td>
                    <td>{{ item.endYear || "-" }}</td>
                    <td>{{ formatCurrency(item.fund) || "-" }}</td>
                    <td>{{ item.currency || "-" }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">
                      {{ bahasa ? "Data tidak ditemukan" : "Data not found" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div
              class="font-bold text-base sm:text-xl my-5 text-left"
              v-if="bahasa">
              Tentang
            </div>
            <div class="font-bold text-base sm:text-xl my-5 text-left" v-else>
              About
            </div>
            <div class="text-left mb-5" v-html="item.desc"></div>
          </v-col>
        </div>
      </v-col>
      <!-- Program -->
      <div class="pb-5 white" v-if="programs.length > 0">
        <v-col cols="6" sm="6" class="sm:block m-auto px-0">
          <div
            class="font-bold text-base sm:text-3xl mb-7 text-left"
            v-if="bahasa">
            Proyek
          </div>
          <div class="font-bold text-base sm:text-3xl mb-7 text-left" v-else>
            Project
          </div>
          <slick ref="slick" :options="slickOptions">
            <!-- class="px-10" -->
            <div
              v-for="(program, index) in programs"
              @click="goToProgram('project-detail', program.id)"
              class="cursor-pointer mb-3 text-left"
              :key="index">
              <v-row>
                <v-col cols="auto" class="pt-4">
                  <img src="@/assets/images/icon_program.svg" width="80%" />
                </v-col>
                <v-col cols="8" sm="10">
                  <div class="font-bold text-xl py-0">{{ program.name }}</div>
                  <!-- <div class="text-sm py-3">{{ program.createdDate }}</div> -->
                  <!-- <div class="font-lora text-base">IDR {{ program.fund }}</div> -->
                </v-col>
              </v-row>
            </div>
          </slick>
          <button class="btn-white-outlined nextProgram-btn">
            <v-icon color="black">arrow_back</v-icon>
          </button>
          <button class="btn-white-outlined backProgram-btn">
            <v-icon color="black">arrow_forward</v-icon>
          </button>
        </v-col>
      </div>
      <v-col
        cols="10"
        sm="8"
        class="my-10 pb-10 mx-auto"
        v-if="item.assessments.length > 0">
        <div
          class="font-bold text-base sm:text-3xl mb-5 text-left"
          v-if="bahasa">
          Hasil penilaian
        </div>
        <div class="font-bold text-base sm:text-3xl mb-5 text-left" v-else>
          Assessment result
        </div>
        <v-row class="mt-2">
          <v-col
            cols="12"
            class="mb-5 px-6"
            v-for="(assessment, index) in item.assessments"
            :key="'asses' + index">
            <v-row>
              <v-col cols="12" sm="2" class="pt-0 px-0">
                <img
                  :src="baseUrlImage + assessment.image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  width="100%" />
              </v-col>
              <v-col cols="12" sm="10" class="text-left">
                <div class="font-bold text-xl py-0">
                  {{ assessment.assessments_by }}
                </div>
                <!-- <div class="text-sm py-3">
                  <span v-if="bahasa">Hasil Penilaian : </span>
                  <span v-else>Assesment Result : </span>
                  <b class="text-orange">Moderate Risk</b>
                </div> -->
                <div
                  class=""
                  v-if="!assessment.is_expand"
                  v-html="strLimit(assessment.desc, 100)"></div>
                <div class="" v-else v-html="assessment.desc"></div>
                <div
                  class="text-primary cursor-pointer font-bold mt-3 mb-2"
                  @click="assessment.is_expand = !assessment.is_expand"
                  v-if="assessment.desc.length > 100">
                  <u v-if="bahasa">Perluas Detail</u>
                  <u v-else>Expand Details</u>
                  <v-icon small color="green darken-1">expand_more</v-icon>
                </div>
                <div
                  class="text-primary cursor-pointer font-bold mt-3 mb-2"
                  @click="assessment.is_expand = !assessment.is_expand"
                  v-if="assessment.desc.length > 100">
                  <u v-if="bahasa">Sembunyikan Detail</u>
                  <u v-else>Collapse Details</u>
                  <v-icon small color="green darken-1">expand_less</v-icon>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <div
        class="grey lighten-4 px-0 pt-10 pb-5 mt-5"
        v-if="stories.length > 0">
        <v-col cols="12" sm="10" class="block m-auto px-0">
          <div class="font-bold text-base sm:text-xl mb-7 text-center">
            <span v-if="bahasa">Kisah & Blog</span>
            <span v-else>Stories & Blogs</span>
          </div>
          <slick ref="slick" :options="slickOptionsStory" class="px-5">
            <div
              v-for="(i, index) in stories"
              :key="index"
              @click="goTo('content-detail', i.id, i.category.toLowerCase())"
              class="cursor-pointer mb-3 pa-5 text-left">
              <v-img
                :src="require('@/assets/images/cover_.png')"
                class="rounded align-center pa-5"
                height="250">
                <v-col
                  cols="12"
                  class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                  <div class="text-sm">{{ i.category || "-" }}</div>
                  <div
                    class="text-lg sm:text-xl tracking-wide py-2"
                    v-html="strLimit(i.desc, 50)"></div>
                  <v-btn
                    class="btn-primary-white mt-3 font-bold text-capitalize">
                    <span v-if="bahasa">Baca selengkapnya</span>
                    <span v-else>Read More</span>
                  </v-btn>
                </v-col>
              </v-img>
            </div>
          </slick>
          <button class="btn-white-outlined nextStory-btn">
            <v-icon color="black">arrow_back</v-icon>
          </button>
          <button class="btn-white-outlined backStory-btn">
            <v-icon color="black">arrow_forward</v-icon>
          </button>
        </v-col>
      </div>
      <div
        class="grey lighten-4 grid justify-items-center px-0 py-5 mt-5"
        v-if="news.length > 0">
        <v-col cols="10" class="pa-0">
          <div class="font-bold text-base sm:text-xl mt-5 mb-10">
            {{ bahasa ? "Berita" : "News" }}
          </div>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-3 text-left"
              v-for="(berita, index) in news"
              :key="index + 'news'"
              @click="goToProgram('news-detail', berita.id)">
              <v-row>
                <v-col cols="3" class="px-0">
                  <img
                    :src="baseUrlImage + berita.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%"
                    class="rounded-md sm:px-3" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-base pt-0 sm:text-xl">
                    {{ berita.title || "-" }}
                  </div>
                  <div class="text-sm py-2 pa-0">{{ berita.date || "-" }}</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <!-- <div class="grey lighten-4 grid justify-items-center px-0 py-5" v-if="stories.length > 0">
        <v-col cols="10" class="pa-0">
          <div class="font-bold text-base sm:text-xl mt-5 mb-10">{{bahasa ? 'Kisah & Blog' : 'Stories & Blogs'}}</div>
          <v-row>
            <v-col cols="6" sm="4" v-for="(item, indexStories) in stories" :key="indexStories" class="px-5 text-left cursor-pointer" @click="goTo('content-detail', item.id, item.category.toLowerCase())">
              <img :src="baseUrlImage + item.image.file_path" width="100%" class="rounded-md h-54" />
              <div class="font-bold text-base sm:text-xl pb-1 pt-2">{{ item.title || '-'}}</div>
              <div class="text-sm pb-1 pa-0">{{ item.category + ' | ' + item.date || '-'}}</div>
              <div class="font-lora pa-0 text-base h-24 sm:h-44" v-html="strLimit(item.desc, 400)"/>
            </v-col>
          </v-row>
        </v-col>
      </div> -->
    </template>
  </div>
</template>
<script>
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  components: {
    Slick,
  },
  data() {
    return {
      items: [],
      slickOptions: {
        slidesToShow: 2,
        prevArrow: ".nextProgram-btn",
        nextArrow: ".backProgram-btn",
        dots: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      slickOptionsXS: {
        slidesToShow: 1,
        prevArrow: ".nextProgram-btn-xs",
        nextArrow: ".backProgram-btn-xs",
      },
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      is_expand: false,
      isLoad: false,
      item: {},
      programs: [],
      lastUpdated: "",
      stories: [],
      news: [],
      slickOptionsStory: {
        slidesToShow: 3,
        prevArrow: ".nextStory-btn",
        nextArrow: ".backStory-btn",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
    headers() {
      return [
        this.bahasa ? "Tahun mulai" : "Start year",
        this.bahasa ? "Tahun selesai" : "End year",
        this.bahasa ? "Jumlah Dana" : "Amount of Dana",
        this.bahasa ? "Mata uang" : "Currency",
      ];
    },
  },
  watch: {
    bahasa() {
      this.getDetail();
    },
  },
  methods: {
    async getDetail() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/donor/" + this.id, { params: param })
        .then((response) => {
          console.log(response.data.data.image.file_path);
          if (response.data.code === 200) {
            this.item = response.data.data;
            this.programs = response.data.data.program;
            this.item.assessments = this.item.assessments.map((item) => {
              return {
                ...item,
                is_expand: false,
              };
            });
            this.stories = response.data.data.news.filter((item) => {
              return (
                item.category === "Blog" ||
                item.category === "Story" ||
                item.category === "Stories"
              );
            });
            this.news = response.data.data.news.filter((item) => {
              return item.category === "News" || item.category === "Berita";
            });
          }
        })
        .catch((error) => {
          if (error.response.data.code === 404) {
            this.$router.push({ name: "NotFound" });
          } else {
            this.isLoad = false;
            Swal.fire("Failed!", error.response.data.message, "error");
          }
        })
        .finally(() => (this.isLoad = false));
    },
    goToProgram(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    goTo(link, param, type) {
      this.$router.push({
        name: link,
        params: {
          id: param,
          type: "/" + type,
        },
      });
    },
  },
};
</script>

<style>
.slick-track {
  margin-left: 0 !important;
}
</style>
